import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { googleMapsApiKey } from "../../../helper/Url";
import { animate } from "framer-motion";

const ClinicMarker = ({ position, title }) => {
  const [markerPosition, setMarkerPosition] = useState(position);

  useEffect(() => {
    setMarkerPosition(position);
  }, [position]);

  const shape = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: "poly",
  };

  return <Marker 
             position={markerPosition} 
             shape={shape} title={title} 
             animation={window.google.maps.Animation.BOUNCE} 
          />;
};

function ClinicMap({ height = "400px", latitude = 46.204941403927414, longitude = 6.13823152762976  }) {
  const center = { lat: latitude, lng: longitude };

  const mapOptions = {
    zoomControl: true,
    scrollwheel: false,
    draggable: true,
    disableDoubleClickZoom: true,
    disableDefaultUI: true, // Remove default controls
  };

  const containerStyle = {
    width: "100%",
    height: height,
  };

  const clinics = [{ name: "Clinic", position: { lat: latitude, lng: longitude } }];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16} options={mapOptions}>
      {clinics.map((clinic, index) => (
        <ClinicMarker key={index} position={clinic.position} title={clinic.name} />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(ClinicMap);
