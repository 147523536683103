import { createSlice } from "@reduxjs/toolkit";
const initialState = {
   selectedData: 
   {
    animaltype: {},
    service: {},
    clinic:{},
    time:{},

   }
};

export const StepsSlice = createSlice({
  name: "-",
  initialState, 
  reducers: {
    setSelectedData: (state, payload) => {
      state.selectedData = payload;
    },
 
  },
});

export const {setSelectedData} = StepsSlice.actions;

export const selectSelectedData= (state) => state.selected.selectedData;

export default StepsSlice.reducer;
