import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    userInfo: {},
};

export const UserInfoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state,payload) => {
      state.userInfo = payload;
    },
  },
});

export const {setUserInfo} = UserInfoSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo;

export default UserInfoSlice.reducer;
