import React from 'react';
import oneclicIllustration from '../../assets/Images/oneclic.vet_bg-picto.png';
import logo from '../../assets/Logos/logo-white.png';

const UnderConstruction = () => {
  return (
    <div className="construction-container">
      <img 
        src={oneclicIllustration} 
        alt="Background Illustration" 
        className="background-image"
      />
      <div className="overlay"></div>
      <div className="content">
        <div className="logo-container">
          <img 
            src={logo} 
            alt="Oneclic.vet Logo" 
            className="logo"
          />
        </div>

        <div className="main-content">
          <div className="gear-icon">
            <svg viewBox="0 0 20 20">
              <path d="M8.34 1.804A1 1 0 019.32 1h1.36a1 1 0 01.98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 011.262.125l.962.962a1 1 0 01.125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.295A1 1 0 0118 9.32v1.36a1 1 0 01-.804.98l-1.473.295c-.144.497-.342.971-.587 1.416l.834 1.25a1 1 0 01-.125 1.262l-.962.962a1 1 0 01-1.262.125l-1.25-.834a6.99 6.99 0 01-1.416.587l-.295 1.473A1 1 0 019.32 19H7.96a1 1 0 01-.98-.804l-.295-1.473a6.99 6.99 0 01-1.416-.587l-1.25.834a1 1 0 01-1.262-.125l-.962-.962a1 1 0 01-.125-1.262l.834-1.25a6.99 6.99 0 01-.587-1.416l-1.473-.295A1 1 0 011 10.68V9.32a1 1 0 01.804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 01.125-1.262l.962-.962A1 1 0 015.38 3.03l1.25.834a6.99 6.99 0 011.416-.587l.295-1.473z"/>
            </svg>
          </div>
          <h1>Oneclic<span>.vet</span> est en construction</h1>
          <p>Le service sera disponible très bientôt.</p>
        </div>
      </div>

      <style jsx>{`
        .construction-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
        }

        .background-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
          filter: none;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          z-index: 2;
          backdrop-filter: none;
        }

        .content {
          text-align: center;
          position: relative;
          z-index: 3;
          max-width: 600px;
          margin: 0 auto;
          padding: 40px;
          animation: fadeIn 1.2s ease-out;
        }

        .logo-container {
          margin-bottom: 60px;
          transform: scale(1.2);
        }

        .logo {
          max-width: 220px;
          height: auto;
          filter: drop-shadow(0 0 10px rgba(0, 182, 170, 0.3));
        }

        .main-content {
          background: rgba(0, 0, 0, 0.2);
          padding: 40px;
          border-radius: 20px;
          box-shadow: 0 8px 32px rgba(0, 182, 170, 0.1);
          backdrop-filter: blur(8px);
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .gear-icon {
          width: 60px;
          height: 60px;
          margin: 0 auto 30px;
          animation: spin 10s linear infinite;
          filter: drop-shadow(0 0 8px rgba(0, 182, 170, 0.5));
        }

        .gear-icon svg {
          width: 100%;
          height: 100%;
          fill: #00B6AA;
        }

        h1 {
          font-size: 2.8rem;
          color: white;
          margin-bottom: 20px;
          font-weight: 600;
          letter-spacing: -0.02em;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        h1 span {
          color: #00B6AA;
          text-shadow: 0 0 15px rgba(0, 182, 170, 0.5);
        }

        p {
          font-size: 1.3rem;
          color: #00B6AA;
          line-height: 1.6;
          text-shadow: 0 0 10px rgba(0, 182, 170, 0.3);
          font-weight: 500;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(30px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @media (max-width: 768px) {
          .content {
            padding: 20px;
          }

          .main-content {
            padding: 30px 20px;
          }

          h1 {
            font-size: 2.2rem;
          }

          p {
            font-size: 1.1rem;
          }

          .logo-container {
            transform: scale(1);
            margin-bottom: 40px;
          }
        }
      `}</style>
    </div>
  );
};

export default UnderConstruction;
