import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyDuyQFoeU1mDqAp_YhdL0r8F9uXeDEs6WU",
  authDomain: "oneclinic-20426.firebaseapp.com",
  projectId: "oneclinic-20426",
  storageBucket: "oneclinic-20426.appspot.com",
  messagingSenderId: "528999590747",
  appId: "1:528999590747:web:659761a0edae1d0c9b487e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);


// Login with Google

export const authPop = () =>
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user; 
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential);
    });

// Login with facebook

    const facebookProvider = new FacebookAuthProvider();

    export const loginWithFacebook = () => {
      signInWithPopup(auth, facebookProvider)
        .then((result) => {
          const user = result.user;
          console.log('Facebook user logged in:', user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Facebook login error: ${errorCode} - ${errorMessage}`);
        });
    };



// Apple Sign-In provider
const appleProvider = new OAuthProvider('apple.com');

export const loginWithApple = () => {
  signInWithPopup(auth, appleProvider)
    .then((result) => {
      const user = result.user;
      console.log('Apple user logged in:', user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(`Apple login error: ${errorCode} - ${errorMessage}`);
    });
};
  

auth.useDeviceLanguage();
