  const translation = JSON.parse(`{
"header.login":"Login",
"header.Register":"Registration",
"header.ifveterian":"Are you a veterinarian ?",
"page.join":"Join",
"page.register.firstname":"First name*",
"page.register.lastname":"Last name*",
"page.register.phone":"Phone number*",
"page.register.email":"Email address*",
"page.register.cemail":"Confirm email address*",
"page.register.address":"Address*",
"page.register.city":"City*",
"page.register.postalCode":"Postal Code*",
"page.register.country":"Country*",
"page.register.password":"Password*",
"page.register.cpassword":"Confirm Password*",
"page.register.terms":"Terms and condition*",
"page.register.adhere":"I adhere to oneclinic.vet terms and conditions",
"page.register.submit":"Submit",
"page.register.firstname.error":"First name is required",
"page.register.lastname.error":"Last name is required",
"page.register.phone.error":"Phone number is required",
"page.register.email.error":"Email is required", 
"page.register.address.error":"Address is required",
"page.register.city.error":"City is required",
"page.register.postalcode.error":"Postalcode is required",
"page.register.country.error":"Country is required",
"page.register.password.error":"Password is required",
"page.register.cpassword.error":"Confirm match please!",
"page.register.cpmatch.error":"Password does not match",
"page.register.terms.error":"Terms and condition is required",
"page.register.passwordPatern.error":"Password should atleast have 8 characters (1 capital letter, 1 number and 1 symbol)",
"page.register.verify":"Verify",
"page.register.verifyUser":"Verify user",
"page.reset.password":"Reset",
"page.change.password":"Change",
"page.forget.password":"Forget Password?",
"page.home.left.title":"Are you a veterinarian ?",
"page.home.right.title":"I find my veterinarian !",
"page.home.left.content":"Register now and simplify your life! Enjoy a simple, intuitive and fast solution to connect with your customers and optimize your time. Discover our innovative platform and our new subscription-free and no-commitment offer, designed specifically for the veterinary profession.",
"page.home.right.content":"Make an appointment online with your veterinarian 24/7 in just a few clicks! From any device, make an appointment for your pet with the veterinarian available nearest you. It's simple, secure and completely free.",
"page.home.left.button":"Veterinary Registration",
"page.home.right.button":"Make an appointment",
"page.register.ide.error":"Identification number is required",
"page.Bienvenue":"Welcome",
"page.myClinicDashboard":"My dashboard in one click",
"page.myanimaux":"My animals",
"page.ajouteanimaux":"Add animal",
"page.animaldetailmessage":"You can also find the details and modify the file of each animal by clicking on its photo or in the sidebar, in the My animals section.",
"page.prochainrendezvous":"My next appointment",
"page.prochainrendezvouspagerdv":"Make another appointment",
"page.myaccountenunclinic":"My account in one click",
"page.myemail":"My email",
"page.changemyemail":"Change my email",
"page.changemypassword":"Change my password",
"page.validatemodification":"Validate the modification",
"page.myinfoenunclinic":"My information in one click",
"page.civilite":"Civility*",
"page.datedenaissance":"Birth date*",
"page.prenom":"Firstname*",
"page.nodefamily":"Family name*",
"page.address":"Adresse*",
"page.postalcode":"Postal code",
"page.ville":"City",
"page.pay":"Countries",
"page.telephone":"Phone",
"page.search":"Search",
"page.confirm.email.error":"Email does not match"
  }`);

export default translation;
