import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "../features/LanguageSlice";
import SearchReducer from "../features/SearchSlice"
import  AnimalReducer from "../features/AnimalsListChange";
import UserInfoSlice from "../features/UserInfoSlice";
import StepsSlice from "../features/StepsSlice";

export const store = configureStore({
  reducer: {
    language: LanguageReducer,
    search: SearchReducer,
    animal: AnimalReducer,
    user: UserInfoSlice,
    selected: StepsSlice,
  },
});
