import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    searchResult: [],
    bookingInfo:[]
};

export const SearchSlice = createSlice({
  name: "-",
  initialState,
  reducers: {
    setSearchedClinics: (state, payload) => {
      state.searchResult = payload;
    },
    setBookingInfo: (state, payload) => {
      state.bookingInfo = payload;
    },
  },
});

export const {setSearchedClinics, setBookingInfo} = SearchSlice.actions;

export const selectSearchResult = (state) => state.search.searchResult;
export const selectBookinginfo = (state) => state.search.bookingInfo;

export default SearchSlice.reducer;
