import React, { useEffect } from "react";
import "./App.scss";
import { Routes, Route, useLocation, useNavigate, Router, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import RegisterPage from "./pages/client/account/Register";
import LoginPage from "./pages/client/account/Login";
import ResetPage from "./pages/client/account/Reset";
import ChangePage from "./pages/client/account/Change";
import ForgetPage from "./pages/client/account/Forget";
import VerifyPage from "./pages/client/account/Verify";
import VetVerifyPage from "./pages/client/account/VetVerify";
import Home from "./pages/client/Home";
import ProtectedRoute from "./helper/Auth/ProtectedRoute";
import ClinicRegisterPage from "./pages/client/account/ClinicRegister";
import Board from "./components/client/customer/Board";
import ClinicBoard from "./components/client/clinic/Board";
import Account from "./components/client/customer/Account";
import ClinicAccount from "./components/client/clinic/Account";
import MyclinicInfo from "./components/client/customer/MyclinicInfo";
import MyclinicInfoClinic from "./components/client/clinic/MyclinicInfo";
import Animals from "./components/client/customer/Animals";
import AnimalDetail from "./components/client/customer/AnimalDetail";
import NotFound from "./components/common/NotFound";
import Rendezvous from "./components/client/customer/Rendezvous";
import CustomerDashboardPage from "./pages/client/customer/CustomerDashboard";
import ClinicDashboardPage from "./pages/client/clinic/ClinicDashboard";
import TimeTable from "./components/client/clinic/TimeTable";
import ClinicServices from "./components/client/clinic/Services";
import Veterinary from "./components/client/clinic/Veterinary";
import VeterinaryAdd from "./components/client/clinic/VeterinaryAdd";
import { General } from "./pages/client/search/General";
import { Booking } from "./pages/client/search/nextConfirm";
import { Confirm } from "./pages/client/search/Confirm";
import { Confirmed } from "./pages/client/search/Confirmed";
import { AXIOS_POST } from "./config/axios";
import { POST_SESSION_VERIFY_URL } from "./helper/Url";
import AddAnimal from "./components/client/customer/AddAnimal";
import RendezvousClinic from "./components/client/clinic/Rendezvous";
import ClinicAnimalDetail from "./components/client/clinic/AnimalDetail";
import InscriptionGeneral from "./pages/client/account/InscriptionGeneral";
import PreClientRegisterPage from "./pages/client/account/PreClientRegisterPage";
import PreClinicRegisterPage from "./pages/client/account/PreClinicRegisterPage";
import CompanyPreInfoPage from "./pages/client/account/CompanyPreInfoPage";
import ClientPreInfoPage from "./pages/client/account/ClientPreInfoPage";
import ScrollToTop from "./components/common/ScrollToTop";
import UnderConstruction from "./components/common/UnderConstruction";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" element={<ScrollToTop><UnderConstruction /></ScrollToTop>}></Route>
    </>
  )
)


function App() {
  return (
    <div className="App">
       <RouterProvider router={router}/>
    </div>
  );
}

export default App;
