import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    animaledited: false
};

export const AnimalListSlice = createSlice({
  name: "animal",
  initialState,
  reducers: {
    setAnimalEdited: (state) => {
      state.animaledited = !state.animaledited;
    },
  },
});

export const {setAnimalEdited} = AnimalListSlice.actions;
export const selectAnimalEdited = (state) => state.animal.animaledited;

export default AnimalListSlice.reducer;
